/* latin */
@font-face {
 font-family: "Poppins";
 src: url(../assets/fonts/pxiEyp8kv8JHgFVrJJfecg.woff2) format("woff2");
}

/* fallback */
@font-face {
 font-family: "Material Icons";
 font-style: normal;
 font-weight: 400;
 src: url(../assets/fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
}

.material-icons {
 font-family: "Material Icons" !important;
 font-weight: normal;
 font-style: normal;
 font-size: 24px;
 line-height: 1;
 letter-spacing: normal;
 text-transform: none;
 display: inline-block;
 white-space: nowrap;
 word-wrap: normal;
 direction: ltr;
 -webkit-font-feature-settings: "liga";
 -webkit-font-smoothing: antialiased;
}

* {
 font-family: "Poppins";
}

*:focus {
 outline: none !important;
}

.MuiMenuItem-root {
 font-size: 12px !important;
 color: #294354;
 font-weight: 500;
 font-family: "Poppins" !important;
}

.MuiPaper-elevation2 {
 box-shadow: unset !important;
}

.MuiPaper-elevation1 {
 box-shadow: 0 0 32px -4px rgba(0, 0, 0, 0.15) !important;
}

.MuiFormHelperText-root.Mui-error {
 font-size: 10px !important;
}

.react-datepicker__day--selected,
.react-datepicker__time-container
 .react-datepicker__time
 .react-datepicker__time-box
 ul.react-datepicker__time-list
 li.react-datepicker__time-list-item--selected {
 background: #cb2625 !important;
}

.react-datepicker__day--selected:hover {
 background: #cb2625 !important;
}

.react-datepicker__navigation--previous {
 border-right-color: #000 !important;
}

.react-datepicker__navigation--next {
 border-left-color: #000 !important;
}

.pagination {
 display: flex !important;
 list-style: none;
 justify-content: center;
 padding-inline-start: 0;
 margin-block-start: 0;
 margin-block-end: 0;
}

.pagination li {
 font-size: 12px;
 cursor: pointer;
 background: #fff;
 border: 1px solid #e2e2e2;
 padding: 10px;
 box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
 border-radius: 5px;
 z-index: 999;
}

.pagination li a {
 text-decoration: none;
 color: #cb2625;
 font-weight: 700;
}

.pagination li.active {
 background: #cb2625 !important;
}

.pagination li.active a {
 color: #fff !important;
}

.pvtUi {
 margin: auto !important;
}

.react-datepicker-popper {
 z-index: 999;
}

#fm-pivot-view {
 z-index: 0;
}

#fm-pivot-view
 .fm-grid-layout
 div.fm-scroll-pane::-webkit-scrollbar-thumb:hover {
 background: #555;
}

#fm-pivot-view .fm-fields-view-wrap button.fm-btn-open-fields {
 display: none;
}

.fm-ui-popup {
 max-height: 450px;
 margin-bottom: 20px !important;
 overflow: hidden;
 overflow-y: scroll;
 top: -80px !important;
}
.fm-popup-options {
 top: -20px !important;
 max-height: 500px;
 overflow-y: scroll;
}

/* CUSTOME CSS */
.MuiInputLabel-outlined {
 z-index: 0;
}

.input_file {
 background-color: #cb2625 !important;
}

.input_file::placeholder {
 color: white !important;
}

.recharts-tooltip-wrapper {
 z-index: 999;
}

.fix_table {
 max-height: 70vh !important;
 min-height: 70vh !important;
}

.fix_pagination {
 position: fixed;
 width: 90vw;
}

.fix_table .MuiTableCell-root {
 border-left: 1px solid #e5e5e5 !important;
 border-right: 1px solid #e5e5e5 !important;
}

.MuiFormLabel-root {
 z-index: 1 !important;
}

.custome_td {
 /* background-color: red; */
 display: block;
 max-width: 400px;
 overflow: scroll;
}

#loader {
 display: flex;
 justify-content: center;
 align-items: center;
 margin-top: 15%;
}

@keyframes ldio-ucwibazh2i9 {
 0% {
  transform: translate(-50%, -50%) rotate(0deg);
 }

 100% {
  transform: translate(-50%, -50%) rotate(360deg);
 }
}

#loader .loadingio-spinner-rolling-3kjtqyynjid .ldio-ucwibazh2i9 div {
 position: absolute;
 width: 20.88px;
 height: 20.88px;
 border: 7.36px solid #cb2625;
 border-top-color: transparent;
 border-radius: 50%;
 animation: ldio-ucwibazh2i9 0.7575757575757576s linear infinite;
 top: 92px;
 left: 92px;
 box-sizing: content-box;
}

#loader .loadingio-spinner-rolling-3kjtqyynjid {
 width: 184px;
 height: 184px;
 display: inline-block;
 overflow: hidden;
 background: none;
}

#loader .loadingio-spinner-rolling-3kjtqyynjid .ldio-ucwibazh2i9 {
 width: 100%;
 height: 100%;
 position: relative;
 transform: translateZ(0) scale(1);
 backface-visibility: hidden;
 transform-origin: 0 0;
 /* see note above */
}
#fm-pivot-view
 div.fm-calculated-view
 .fm-popup-content
 .fm-formula-wrap
 .fm-prompt {
 color: red !important;
 display: block !important;
}
.MuiSlider-thumb {
 background-color: #cb2625 !important;
}
.MuiSlider-track {
 background-color: #cb2625 !important;
}
.MuiSlider-rail {
 background-color: #cb2625 !important;
}

/* datepicker */
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value,
.rs-picker-toolbar-option,
.rs-calendar-header-title {
 color: #cb2625 !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
 border: 1px solid #cb2625 !important;
}

.rs-picker-toolbar-right-btn-ok,
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
 background-color: #cb2625 !important;
 border-color: #cb2625 !important;
}

.rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled):hover {
 border-color: #cb2625 !important;
}

.fullscreen-enabled {
 background: #fff;
}
.MuiBadge-colorError {
 font-size: 10px;
}
.MuiBadge-anchorOriginTopRightRectangle {
 right: -5px !important;
}
.MuiBadge-colorPrimary {
 background-color: green !important;
}
.MuiTimelineOppositeContent-root {
 flex: unset !important;
}
.MuiTimelineContent-root {
 border-bottom: 1px solid #e8e8e8;
}

.MuiDataGrid-root .MuiButton-textPrimary {
 color: #1fb47a !important;
}

.customeSwitch .MuiSwitch-colorSecondary.Mui-checked {
 color: #1fb47a !important;
}

.customeSwitch .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
 background-color: #1fb47a !important;
}

.box_online {
 border-radius: 20px;
 padding: 1px 2px 1px 9px;
}

.box_online {
 background-color: #ddffd1;
}
.box_online .MuiTypography-root {
 color: #53bc2e;
 font-size: 12px;
}
.box_online svg {
 color: #53bc2e;
 font-size: 13px;
}
